.level.mb-2 {
    .level-left {
        .level-item {
            @include touch {
                justify-content: flex-start;
            }
        }
    }
}


