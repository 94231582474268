tr {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      background-color: rgba($color, 0.04);
      border-left: $border-width-table-row-highlighted solid $color;
      color: $dark;
    }
  }
}

.table-container {
  @include touch {
    border-radius: $card-radius;
  }
  @include desktop {
    border-radius: $card-radius-desktop;
  }

  td {
    i.icon {
      color: $black !important;
    }
  }
}

table {
  @include touch {
    font-size: $size-8;
  }
}
