.m-auto {
    margin: auto;
}

.is-loading-page {
    width: 100%;
    height: calc(100vh - #{$navbar-height * 2});
    display: flex;
    align-items: center;
    justify-content: center;

}
