thead {
  white-space: nowrap;
}

tr {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      background-color: $color-invert;
      border-left: $border-width-table-row-highlighted solid $color;
      color: $color;
    }
  }
}

th {
  white-space: nowrap;
  text-transform: uppercase;
}

td {
  &.is-narrow {
    padding-top: 0.8em;
    padding-bottom: 0.8em;
  }
}

// override
.table.is-narrow td,
.table.is-narrow th {
  padding: $table-cell-padding-narrow;
}

case-image,
case-image-yield {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

td,
th {
  vertical-align: middle !important;

  &:first-child {
    padding-left: 20px;
  }

  a {
    color: inherit;

    &:hover,
    &:focus {
      color: inherit;
    }
  }
}

.more {
  height: 100%;
  position: relative;

  >span {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.min-width-cell {
  min-width: 100px;
}
