//controls
$control-padding-vertical: 0.375em !default;
$control-padding-horizontal: math.div($gap, 2) !default;
$input-background-color: $white-ter !default;
$input-placeholder-color: rgba($black-ter, 0.5) !default;
$input-color: $black !default;
$input-radius: 3px !default;
$input-arrow: $black-ter !default;
$input-icon-color: $black-ter !default;
$input-icon-active-color: $link !default;

$input-border-color: $grey-light !default;

$input-shadow: none !default;

$input-hover-color: $black-ter !default;
$input-hover-border-color: rgba($black-ter, 0.05) !default;

$input-focus-border-color: $link !default;
$input-focus-color: $link !default;
$input-focus-box-shadow-size: 0 0 5px 0 !default;

$input-disabled-color: $grey !default;
$input-disabled-background-color: $grey-light !default;
$input-disabled-border-color: $grey-dark !default;
$input-disabled-placeholder-color: bulmaRgba(
  $input-disabled-color,
  0.3
) !default;

$label-color: $grey !default;

//Datepicker
$calendar-height: 268px !default;
$year-height: 42px !default;
$day-cell-height: 32px !default;
$day-cell-box-shadow-size: inset 0 0 0 2px !default;

//Custom variables
$field-background: $white-bis !default;
$border: $grey-light !default;
$checkbox-spacing: 35px !default;
$checkbox-box-shadow-width: 0 0 0 4px !default;
$select-font-size: 1.5rem !default;
