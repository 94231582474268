.modal-card {
  @include mobile {
    min-height: 100vh;
    border-radius: 0;
  }
}

.modal-card-head {
  @include mobile {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.modal-card-footer {
  @include mobile {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.modal.is-large .modal-content,
.modal.is-large .modal-card {
  @include tablet {
    min-width: 768px;
    max-width: 100vw;
  }
}

.modal {
  z-index: 999999;

  .modal-card-title {
    flex: 1;
  }
}
