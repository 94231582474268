// Fonts
/* arabic */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3Uyf4LnlY1PK6ycZg.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3U6f4LnlY1PK6ycZg.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3Uzf4LnlY1PK6ycZg.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3U1f4LnlY1PK6ycZg.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3U5f4LnlY1PK6ycZg.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Rubik';
    font-style: italic;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWbBXyIfDnIV7nEt3KSJbVDV49rz8tdE3U3f4LnlY1PK6w.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* arabic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nErXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* arabic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nErXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* arabic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nErXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 500;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* arabic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nErXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}

/* cyrillic-ext */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nMrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nFrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* hebrew */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nDrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin-ext */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nPrXyw023e1Ik.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    src: url(../../../../assets/fonts/rubik/iJWKBXyIfDnIV7nBrXyw023e.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@import '@fortawesome/fontawesome-free/css/all';
