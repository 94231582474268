$black: #000000 !default;
$grey: #545356 !default;
$white: #fff !default;
$light: #fff !default;

$darkness: $black !default; // Some titles
$dark: #000000 !default;
$light-bis: #F7F7FA !default; // Sheet
$light: #FFFFFF !default; // Sheet

$black-bis: #242424 !default;
$black-ter: #363636 !default;
$grey-darker: #242325 !default;
$grey-dark: #363537 !default;
$grey-light: #9E9CA0 !default;
$grey-lighter: #CBC9CD !default;
$white-ter: #F1F0F3 !default;
$white-bis: #F7F7FA !default;

$success: #B3F4B9 !default;
$link: #B3F4B9 !default;
$info: #6167F4 !default;
$primary: #A3D3FD !default;
$danger: #EB7A94 !default;
$warning: #FFF968 !default;
$grass: #7BD483 !default;
$sand: #FCE995 !default;
$purple: #E6C0FB !default;

$success-dark: #78b17e !default;
$link-dark: #78b17e !default;
$info-dark: #2d308e !default;
$primary-dark: #6287a7 !default;
$danger-dark: #944356 !default;
$warning-dark: #b4af38 !default;
$grass-dark: #4c9252 !default;
$sand-dark: #a79751 !default;
$purple-dark: #8c6f9c !default;

$success-light: #cef0d1 !default;
$link-light: #cef0d1 !default;
$info-light: #a4a7ff !default;
$primary-light: #c6e0f7 !default;
$danger-light: #e4abb8 !default;
$warning-light: #f2ef9e !default;
$grass-light: #bfe5c2 !default;
$sand-light: #fff5cb !default;
$purple-light: #f4deff !default;



$border-color: $white-ter !default;

// Additional values

$link-alpha10: rgba($link, 0.1) !default;
$link-alpha07: rgba($link, 0.07) !default;
$link-alpha03: rgba($link, 0.03) !default;
$border-color-input: $link-alpha10 !default;

$sidenav-item-hover-color: $link !default;
$sidenav-item-color: $black !default;
