%is-hovered {
    background-color: $sidenav-item-hover-color;
    color: $dark;
  }
  .menu-list {
  
    a {
  
      &:hover {
        @extend %is-hovered;
      }
  
      &.is-active {
        @extend %is-hovered;
        &:before {
          background-color: $dark;
        }
      }
    }
  }
  
  .flex {
    display: flex;
  }
  