.has-position-fixed, app-redirection-button {
    @include touch {
        position: fixed;
        left: 50%;
        bottom: $gap;
        transform: translateX(-50%);
        z-index: 999;
        margin: 0 !important;
    }
}

.button.is-large-touch {
    @include touch {
        height: $perimeter-large;
        width: $perimeter-large;
        border-radius: $perimeter-large;
    }
}

.button:not(.is-circle) {
    @include touch {
        height: 60px;
        line-height: 60px;
    }
    &.has-icon {
        @include touch {
            padding: 0 40px !important;
        }
    }
}

