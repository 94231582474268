.date-range-wrapper {
    > .input-text {
        border: 0;
    }

     > div:last-child {
        @include touch {
            margin-bottom: 0;
        }
     }
}

.input-text {
    &:first-child {
    
        + div > input[angular-mydatepicker] {
          border-top-right-radius: 124px;
          border-bottom-right-radius: 124px;
        }
    }
    + div > input[angular-mydatepicker] {
    border-top-left-radius: 124px;
    border-bottom-left-radius: 124px;
    }
}

.ng-mydp {

    @include mobile {
    .myDpSelector.myDpSelectorArrow.myDpSelectorArrowLeft.myDpSelectorAbsolute {
            right: -50px !important;
            left: unset !important;
            width: 100vw !important;
        }
        .myDpSelectorArrowLeft:after {
            left: 50% !important;
            transform: translateX(-50%) !important;
        }
        .myDpSelectorArrowLeft:before {
            left: calc(50% + 1px) !important;
            transform: translateX(-50%) !important;
        }
        .myDpSelectorArrow:after {
            
        }
    }

    .myDpSelectedDay .myDpDayValue,
    .myDpSelectedDay:hover .myDpDayValue {
    color: $dark;
    }
    
    .myDpMarkCurrDay {
        box-shadow: $day-cell-box-shadow-size $purple;
        color: $grey-light;
    }

    .myDpTableSingleDay,
    .myDpTableSingleMonth,
    .myDpTableSingleYear {
      
      &:hover {
        
        color: $dark;
      }
    }
    .myDpFooterBtn:focus,
    .myDpHeaderBtn:focus,
    .myDpMonthLabel:focus,
    .myDpYearLabel:focus {
      color: $dark !important;
    }
    .myDpDaycell {
        &:hover {
          .myDpDayValue {
            color: $dark;
            background-color: $link-light;
          }
        }
    }
    .myDpTableSingleMonth, .myDpTableSingleYear {
        padding: $column-gap;
    }

     .myDpCurrMonth,  .myDpMarkCurrMonth,  .myDpMarkCurrYear {
        font-size: 1.2rem;
    }
     .myDpSelectedYear,  .myDpSelectedMonth {
        color: $dark;
    }
}
