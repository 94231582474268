.multisearch-box {
  .list {  
    li {
      align-items: center;
      &.is-active, &:hover {
        background-color: $link;
        color: $dark;
      }
    }
  }
  .tags .tag {
    @include touch {
      font-size: 18px;
    }
  }
}
    