@use 'sass:list';
@use 'sass:math';
/* You can add global styles to this file, and also import other style files */
/* insert custom variables here to override existing ones or create new ones */
@import 'styles/variables/all';
@import './app/abacus/styles/main.scss';

/* Import custom style here */
@import 'styles/components/reset';
@import 'styles/components/buttons';
@import 'styles/components/breadcrumbs';
@import 'styles/components/cards';
@import 'styles/components/datepicker';
@import 'styles/components/dropdown';
@import 'styles/components/forms';
@import 'styles/components/generic';
@import 'styles/components/grid';
@import 'styles/components/layout';
@import 'styles/components/levels';
@import 'styles/components/multisearch';
@import 'styles/components/side-menu';
@import 'styles/components/table';
@import 'styles/components/type';

// Layout
@import 'styles/layout/camera';
