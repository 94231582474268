.mt-0-small-mobile {
    @media screen and (max-width: 376px) {
      margin-top: 0 !important;
    }
  }
  
  .mb-0-small-mobile {
    @media screen and (max-width: 376px) {
      margin-bottom: 0 !important;
    }
  }
  
  .ml-empty {
    margin-left: 123px !important;
  }
