@include touch {
  nav.navbar {
    position: relative;
    z-index: 9;
    height: $navbar-height;
    width: 100vw;
    background-color: $sidenav-background-color;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      z-index: -1;
      height: 100%;
      width: 100%;
      border-bottom: 1px solid rgba($sidenav-item-color, 0.1);

      @include tablet {
        left: 0;
        width: 100%;
      }
    }
    > div:first-child {
      > a {
        margin: 0;
        padding: 0;
      }
    }

    .navbar-burger {
      height: $navbar-height;
      width: 76px;
      color: $sidenav-item-color;
      @include tablet {
        width: $navbar-height;
      }

      &:hover {
        background-color: transparent;
        color: $sidenav-item-color;
      }
    }

    .header-right {
      display: flex;
      justify-content: center;
      align-items: center;
      height: $navbar-height;
      width: $navbar-height;
    }
  }

  .logo {
    display: inline-block;
    padding: 8px 0 0 $gap;
  }

  .dashboard-title {
    display: inline-block;
    padding-left: 20px;
    font-size: 15px;
    font-family: 'Georgia', 'Times New Roman', sans-serif;
    color: $black;
  }
  .navbar-item img {
    max-height: unset;
  }

  //_ Dropdown

  .drop--down {
    left: unset;
    right: 0;
    top: 30px;
    &:after {
      content: '';
      position: absolute;
      top: -1px;
      right: 30px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent rgba($sidenav-item-color, 0.1)
        transparent;
    }
    &:before {
      content: '';
      position: absolute;
      top: -2px;
      right: 30px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 6px 6px;
      border-color: transparent transparent rgba($sidenav-item-color, 0.1)
        transparent;
    }
  }

  .user-box > a {
    width: 39px;
    height: 100%;
    display: inline-flex;
    box-sizing: content-box;
    align-items: center;
    justify-content: center;
    margin-right: 32px;
    @include mobile {
      margin-right: 30px;
    }
  }

  .buttons {
    padding-right: 110px;
  }

  

  .dropdown-menu.is-small {
    border: 1px solid $border-color;
  }

  .navbar-menu {
    display: block;
    height: calc(100vh - #{$navbar-height});
    width: 260px;
    position: absolute;
    top: $navbar-height;
    left: -261px;
    z-index: 99999;
    box-shadow: 1px 0 0 0 rgba($sidenav-background-color, 0.1);
    padding-bottom: 0;
    background-color: $sidenav-background-color;
    @include mobile {
      width: 100vw;
      left: -100vw;
    }

    &.is-active {
      left: 0;
    }
  }
  // Sub menu on Touch

  .menu-list {
    &:first-child {
      height: calc(100vh - #{$navbar-height} - 2.5rem);
      position: relative;
      overflow: scroll;
    }
    li {
      display: inline-block;
      width: 100%;
      font-size: $size-5;

      a {
        position: relative;
        color: $sidenav-item-color;
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        padding: 19px $gap;
        transition: color 0.25s ease;

        i {
          height: 18px;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 5px;
          background-color: transparent;
        }
        transition: color 0.35s ease, background-color 0.05s ease-out;
        &.has-submenu {
          justify-content: space-between;
          display: inline-flex;

          > span {
            display: inline-flex;
            align-items: center;
          }

          + ul {
            $border-color: rgba($grey-lighter, 0.1);
            padding-top: 1px;
            border-left: 0;
            &:not(.last-ul) {
              border-bottom: 1px solid $border-color;
            }

            li a {
              padding-left: 62px;
              &.is-active {
                background-color: $link;
                color: $dark !important;
                &:before {
                  background-color: $link;
                }
              }
            }
          }
        }
      }
    }
    a {
      border-radius: 0;
      &:hover {
        background-color: $link;
        color: $dark !important;
        transition: color 0.35s ease, background-color 0.05s ease-in;
      }
      &.is-active {
        background-color: $link;
        color: $dark !important;
        &:before {
          background-color: $link;
        }
      }
    }
  }

  .navbar-end {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .menu-list--bottom {
      li:last-child {
        border-top: 1px solid rgba($sidenav-item-color, 0.1);
      }
    }
  }

  .hiding-layer {
    display: block;
    position: absolute;
    top: $navbar-height;
    left: 0;
    width: 100vw;
    opacity: 0;
    z-index: 9999;
    visibility: hidden;
    height: calc(100vh - #{$navbar-height});
    background: $black;
  }

  .link-back {
    height: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: $gap !important;
  }
}

